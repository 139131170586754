import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Getting Started",
  "sort": 3,
  "title": "OpenID Connect best security practices",
  "subtitle": "Learn about key security guidelines for implementing basic OpenID Connect authentication in client applications."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In OpenID Connect(OIDC) authentication flows, there are two main roles:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Authorization Server (OpenID Provider)`}</strong>{`: the server that authenticates the user and issues ID tokens and access tokens. It handles user authentication and returns the user’s identity information to the client application.`}</li>
      <li parentName="ol"><strong parentName="li">{`Client Application`}</strong>{`: the application requesting authentication on behalf of the end user.`}</li>
    </ol>
    <p>{`Criipto Verify is an OpenID Provider, handling most of the process. However, it is important for developers of client applications to also follow security best practices.`}</p>
    <h2>{`Authorization Code Flow`}</h2>
    <p>{`The most secure method of performing OIDC authentication is the `}<a parentName="p" {...{
        "href": "/verify/getting-started/oidc-intro/#authenticate-with-back-channel-authorization-code-flow"
      }}>{`Authorization Code Flow`}</a>{`, or its extended version — `}<a parentName="p" {...{
        "href": "/verify/getting-started/oidc-intro/#authenticate-with-pkce"
      }}>{`Authorization Code Flow with PKCE`}</a>{`. `}</p>
    <p>{`In the Authorization Code Flow, the client application exchanges an authorization code for tokens at the token endpoint. When using Criipto Verify for eID authentication: After the end user successfully logs in with their `}<a parentName="p" {...{
        "href": "/verify/e-ids"
      }}>{`eID`}</a>{` and is redirected back to the client application through the redirect URL, the client application retrieves the authorization code from the URL. The client application then uses this code to request an ID token from Criipto Verify (see `}<a parentName="p" {...{
        "href": "/verify/getting-started/oidc-intro/#exchange-the-code-for-a-token"
      }}>{`code for token exchange`}</a>{`).`}</p>
    <p>{`The Authorization Code Flow enhances security by mitigating replay attempts by attackers (when an attacker tries to reuse a valid intercepted token) and generally reduces the attack surface since ID tokens are not exposed in URLs.`}</p>
    <p><strong parentName="p">{`Best practice:`}</strong></p>
    <ul>
      <li parentName="ul">{`Public clients — such as single-page applications and native applications — must use the `}<strong parentName="li">{`PKCE flow`}</strong>{` to prevent misuse of authorization codes.`}</li>
      <li parentName="ul">{`Confidential clients — such as traditional server-based web applications – are encouraged to use the `}<strong parentName="li">{`PKCE flow`}</strong>{` as well. This is a recommended approach, since `}<strong parentName="li">{`PKCE`}</strong>{` provides strong protection against misuse and injection of authorization codes, making it a more secure option.`}</li>
      <li parentName="ul">{`Alternatively, confidential clients can implement the `}<strong parentName="li">{`Authorization Code Flow`}</strong>{`.`}</li>
      <li parentName="ul">{`Confidential clients implementing the `}<strong parentName="li">{`Authorization Code Flow`}</strong>{` (without `}<strong parentName="li">{`PKCE`}</strong>{`) should take additional precautions, such as using the `}<inlineCode parentName="li">{`nonce`}</inlineCode>{` parameter and the respective claim in the `}<inlineCode parentName="li">{`id_token`}</inlineCode>{`, and one-time tokens carried in the `}<inlineCode parentName="li">{`state`}</inlineCode>{` parameter.`}</li>
      <li parentName="ul">{`For use cases where authentication is initiated on one device but completed on another (e.g., a call center agent starts the authentication process for the end user), the back-channel `}<a parentName="li" {...{
          "href": "/verify/getting-started/oidc-intro/#authenticate-with-ciba"
        }}>{`CIBA flow`}</a>{` is the right choice.`}</li>
      <li parentName="ul">{`The Implicit Flow is obsolete and `}<a parentName="li" {...{
          "href": "/verify/getting-started/best-security-practices/#avoid-implicit-flow"
        }}>{`should not be used in production applications`}</a>{`.`}</li>
    </ul>
    <h2>{`The nonce parameter`}</h2>
    <p><inlineCode parentName="p">{`nonce`}</inlineCode>{` is used to associate an ID token to the specific authorization request and user session.`}</p>
    <p>{`The client application should bind `}<inlineCode parentName="p">{`nonce`}</inlineCode>{` to the user agent session and send it with the initial `}<a parentName="p" {...{
        "href": "/verify/getting-started/oidc-intro/#example-authorization-url"
      }}>{`authorization request`}</a>{` to the OpenID Provider. The binding is done using HTTPOnly, Secure cookies, and preferably with a server-side encrypted value for the `}<inlineCode parentName="p">{`nonce`}</inlineCode>{` (using authenticated encryption).
The OpenID Provider puts the received `}<inlineCode parentName="p">{`nonce`}</inlineCode>{` value into the `}<inlineCode parentName="p">{`id_token`}</inlineCode>{` that is issued as part of the `}<a parentName="p" {...{
        "href": "/verify/getting-started/oidc-intro/#exchange-the-code-for-a-token"
      }}>{`code exchange`}</a>{` at the token endpoint.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`nonce`}</inlineCode>{` serves as a `}<a parentName="li" {...{
          "href": "/verify/getting-started/best-security-practices/#always-validating-jwts"
        }}>{`token validation`}</a>{` parameter. If an attacker injects an authorization code in the authorization response, the `}<inlineCode parentName="li">{`nonce`}</inlineCode>{` value in the client session and the `}<inlineCode parentName="li">{`nonce`}</inlineCode>{` value in the `}<inlineCode parentName="li">{`id_token`}</inlineCode>{` received from the token endpoint will not match, and the attack will be detected. `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`nonce`}</inlineCode>{` also helps protect against an attacker potentially intercepting a valid `}<a parentName="li" {...{
          "href": "/verify/getting-started/best-security-practices/#the-state-parameter"
        }}><inlineCode parentName="a">{`state`}</inlineCode>{` parameter`}</a>{`, but then providing a `}<inlineCode parentName="li">{`code`}</inlineCode>{` value tied to a different session. This could be a concern for public clients, and, in theory, in some confidential client scenarios.`}</li>
    </ul>
    <p><strong parentName="p">{`Best practice:`}</strong>{` For confidential clients implementing the Authorization Code Flow, it is strongly recommended to always include a `}<inlineCode parentName="p">{`nonce`}</inlineCode>{` parameter in authorization requests to protect against misuse of authorization codes.
The client application should then validate the `}<inlineCode parentName="p">{`nonce`}</inlineCode>{` in the received `}<inlineCode parentName="p">{`id_token`}</inlineCode>{`, and not use any issued tokens until this check has succeeded.
`}<em parentName="p">{`For public clients using the PKCE flow, the `}<inlineCode parentName="em">{`nonce`}</inlineCode>{` parameter can also provide an additional layer of security. However, it should only be used in addition to PKCE, not as a substitute for it.`}</em></p>
    <h2>{`The state parameter`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`state`}</inlineCode>{` parameter helps prevent Cross-Site Request Forgery (CSRF) attacks by maintaining state between the client application and the authorization server. `}</p>
    <p>{`When initiating an `}<a parentName="p" {...{
        "href": "(/verify/getting-started/oidc-intro/#example-authorization-url)"
      }}>{`authorization request`}</a>{`, the client application generates a random value and includes it in the `}<inlineCode parentName="p">{`state`}</inlineCode>{` parameter.
This random value links the request to the redirect URL to the client session. Once the user is authenticated, the authorization server redirects the user back to the client application's redirect URL, including the same `}<inlineCode parentName="p">{`state`}</inlineCode>{` value in the URL. For example: `}<inlineCode parentName="p">{`https://your-app.com/callback?code=authorization-code&state=unique-state-value`}</inlineCode></p>
    <p>{`Similarly, if the `}<inlineCode parentName="p">{`state`}</inlineCode>{` parameter was included in the authorization request, the error responses from the authorization server will also contain the `}<inlineCode parentName="p">{`state`}</inlineCode>{` parameter, providing protection against attacker-forged error responses. `}</p>
    <p>{`Overall, the `}<inlineCode parentName="p">{`state`}</inlineCode>{` parameter allows the client application to verify that the authorization response is genuine and was sent by the authorization server in response to the original authorization request.`}</p>
    <p><strong parentName="p">{`Best practice:`}</strong>{` Always generate a unique, random `}<inlineCode parentName="p">{`state`}</inlineCode>{` value for each authorization request and validate it when the response is received.`}</p>
    <h2>{`Always validating JWTs`}</h2>
    <p>{`Upon successful user authentication, Criipto Verify will issue an `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/verify/getting-started/token-contents/"
        }}>{`ID token`}</a></strong>{` to the client application. `}<inlineCode parentName="p">{`id_token`}</inlineCode>{` contains user authentication information in the form of a JSON Web Token (JWT).
However, receiving a JWT is not enough - your application must also `}<a parentName="p" {...{
        "href": "/verify/getting-started/oidc-intro/#validate-the-jwt"
      }}>{`validate it`}</a>{`. Without validation, there’s no guarantee that the token hasn’t been tampered with or forged.`}</p>
    <p><em parentName="p">{`Note: `}<inlineCode parentName="em">{`id_token`}</inlineCode>{` must be validated, even if using the `}<inlineCode parentName="em">{`/userInfo`}</inlineCode>{` endpoint for personal data.`}</em></p>
    <p><strong parentName="p">{`Best practice:`}</strong>{` Always delegate token validation to trusted libraries like `}<a href='https://github.com/panva/jose' target="_blank">{`jose`}</a>{` or choose one of `}<a parentName="p" {...{
        "href": "/verify/integrations"
      }}>{`Criipto's integrations`}</a>{`. Libraries like these handle all the complexities involved in checking JWT signatures, token expiration, etc. Manual validation should be avoided.
More information on how JWT validation works is available in our `}<a href='https://www.criipto.com/blog/jwt-validation-guide' target="_blank">{`JWT validation guide`}</a>{`.`}</p>
    <h2>{`Client authentication`}</h2>
    <p>{`For client authentication, it is recommended to use asymmetric cryptography, such as `}<a parentName="p" {...{
        "href": "/verify/guides/privatekey-jwt/"
      }}>{`Private Key JWTs`}</a>{`. This provides stronger security compared to symmetric key methods.`}</p>
    <p>{`When using asymmetric cryptography, authorization servers do not need to store sensitive symmetric keys, reducing the risk of key leakage. Additionally, key rotation is simplified because you can provide a JWT keys set in a URL, allowing Criipto Verify to dynamically fetch the updated keys.`}</p>
    <p><strong parentName="p">{`Best practice:`}</strong>{` Use Private Key JWTs for client authentication to enhance security and simplify key management.`}</p>
    <h2>{`Avoid Implicit Flow`}</h2>
    <p>{`The Implicit Flow has been deprecated and is considered insecure due to its vulnerability to token leakage and token replay attacks. Client applications should not use the Implicit Flow or any other method that causes the authorization server to issue tokens directly in the authorization response (such as `}<inlineCode parentName="p">{`response_type=id_token`}</inlineCode>{`).`}</p>
    <p>{`Tokens in the Implicit Flow are exposed in the URL fragment (`}<inlineCode parentName="p">{`response_mode=fragment`}</inlineCode>{`), making them vulnerable to interception and mishandling. While `}<inlineCode parentName="p">{`response_mode=fragment`}</inlineCode>{` is convenient and easy to use during development and testing, it is insecure for production applications for several reasons:`}</p>
    <ul>
      <li parentName="ul">{`Tokens in a URL fragment can be exposed to browser history, referral headers, and intermediaries.`}</li>
      <li parentName="ul">{`Browser components or extensions may access the fragment, increasing the risk of interception or token leakage.`}</li>
      <li parentName="ul">{`Some modern browsers restrict or block the use of URL fragments in certain scenarios, which causes compatibility issues. `}</li>
    </ul>
    <h3>{`Use response_mode=query`}</h3>
    <p>{`For improved security, use `}<inlineCode parentName="p">{`response_mode=query`}</inlineCode>{`, which returns authorization parameters in the query string of the redirect URL. This approach is more secure and is the default `}<inlineCode parentName="p">{`response_mode`}</inlineCode>{` for the Authorization Code Flow and PKCE.`}</p>
    <h2>{`More information`}</h2>
    <p>{`For detailed information on security requirements and other recommendations for clients and servers implementing OAuth 2.0 and OpenID Connect, check `}<a href='https://datatracker.ietf.org/doc/html/draft-ietf-oauth-security-topics' target="_blank">{`OAuth 2.0 Security Best Current Practice`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      